import { AxiosProgressEvent } from "axios";
import clsx from "clsx";
import { ChangeEventHandler, useState } from "react";
import { useField, UseFieldConfig, useForm } from "react-final-form";
import authService from "src/services/authService";
import euporiaService from "src/services/euporiaService";
import { useSelector } from "src/store";
import { useUploadContext } from "src/ui/containers/dashboard/files/context/UploadContext";
import { useUploadFile } from "src/ui/containers/dashboard/files/hooks/entities";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import ArrowFileUp from "@dashboard/files/components/ArrowFileUp";
import { FormControl, FormHelperText, Snackbar, Theme } from "@mui/material";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { showErrorToast, showSuccessToast } from "../toasts/Toasts";

const useStyles = makeStyles((theme: Theme) => ({
    label: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "2px dashed " + theme.palette.shadow[200],
        backgroundColor: theme.palette.shadow[50],
        borderRadius: 12,
        padding: 20,
        transition: "background 0.3s ease",
        flex: 1,
    },
    error: {
        borderColor: "red",
        color: "red",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    title: {
        fontSize: 18,
        marginBottom: 5,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    underline: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    caption: {
        fontSize: 14,
        textAlign: "center",
    },
    mailLink: {
        color: theme.palette.blue[200],
    },
    forumLink: {
        color: theme.palette.white[50] + " !important",
        textDecoration: "underline",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.blue[150],
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.red[50],
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        backgroundColor: theme.palette.shadow[100],
    },
}));
const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.blue[150],
    "&.Mui-error": {
        color: theme.palette.red[50],
    },
}));

const forbiddenFileTypes = [
    "apk+",
    "apkm",
    "apkx",
    "deb",
    "exe",
    "gif",
    "htm",
    "html",
    "jpeg",
    "jpg",
    "mp3",
    "png",
    "ppt",
    "pptx",
];

interface FormValues {
    files: File[];
    target: number | undefined;
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    groupId?: number;
    config?: UseFieldConfig<string, string>;
};
export const UploadFileField = ({ config, groupId, name, ...props }: Props) => {
    const classes = useStyles();

    const form = useForm<FormValues>();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const { input, meta } = useField(name, { ...config, type: "file" });
    const error = meta.touched && meta.error;
    const isExpired = useSelector(selectSubscriptionExpired);
    const user = authService.getCurrentUser();

    const { abortController, setUploadStarted, setUploadProgress, setUploadedFileIds } = useUploadContext();
    const { upload } = useUploadFile();

    const onSubmit = async () => {
        setUploadStarted(true);
        const formValues = form.getState().values;

        try {
            for (const file of formValues.files) {
                if (abortController.signal.aborted) {
                    throw new Error("Upload canceled");
                }

                await upload({
                    fileToUpload: file,
                    controller: abortController,
                    groupId,
                    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                        if (progressEvent.total) {
                            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                            const overallProgress = Math.round((progress / 100 / formValues.files.length) * 100);
                            setUploadProgress(overallProgress);
                        }
                    },
                    setUploadedFileIds,
                });
                if (!abortController.signal.aborted) {
                    showSuccessToast("Upload successful");
                    euporiaService.createTransaction("upload", {
                        user_email: user?.email,
                        file_name: file,
                        file_size: file.size,
                    });
                }
            }
        } catch (err) {
            const uploadError = err as Error;
            if (uploadError.message !== "Upload canceled") {
                showErrorToast(uploadError.message);
            }
        } finally {
            setUploadProgress(0);
            setUploadStarted(false);
            form.reset();
        }
    };

    const onDragOver = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
    };
    const handleFileValidation = (files: File[] | null) => {
        if (!files) {
            return false;
        }

        for (const file of files) {
            const fileExtension = file.name.split(".").pop()?.toLowerCase();
            if (forbiddenFileTypes.includes(fileExtension || "")) {
                setOpenSnackbar(true);
                return false;
            }
        }
        return true;
    };
    const onDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        if (!props.disabled) {
            const files = Array.from(event.dataTransfer.files);
            if (handleFileValidation(files)) {
                input.onChange(files);
                await onSubmit();
            }
        }
    };
    const onChange: ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
        const files = Array.from(target.files || []);
        if (files.length && handleFileValidation(files)) {
            input.onChange(files);
            await onSubmit();
        } else {
            target.value = "";
        }
    };

    if (isExpired) {
        return (
            <Alert severity="error">
                Your subscription has expired. Please visit:
                <a href="https://emteria.com/p/subscription" className={classes.mailLink}>
                    &nbsp;https://emteria.com/p/subscription&nbsp;
                </a>
                to enew your subscription and continue using our services.
            </Alert>
        );
    }

    return (
        <>
            <StyledFormControl
                className={props.className}
                error={error}
                style={{ opacity: props.disabled ? 0.5 : 1 }}
                fullWidth
            >
                <label
                    htmlFor="fileInput"
                    className={clsx(classes.label, error ? classes.error : "")}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                >
                    <div className={classes.container}>
                        <ArrowFileUp color={error ? "red" : undefined} />
                        <div className={classes.title}>
                            <span className={classes.underline}>Click here to select</span>
                            <span>&nbsp;or drag and drop file to upload</span>
                        </div>
                        <span className={classes.caption}>Max file size: 256 MB</span>
                        <input id="fileInput" type="file" multiple={true} onChange={onChange} hidden {...props} />
                    </div>
                </label>
                <StyledFormHelperText>{error}</StyledFormHelperText>
            </StyledFormControl>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert variant="filled" onClose={() => setOpenSnackbar(false)} severity="info">
                    This type of file is not supported yet. Please let us know in our{" "}
                    <a
                        className={classes.forumLink}
                        href="https://forum.emteria.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        forum
                    </a>{" "}
                    if such files are important for your product.
                </Alert>
            </Snackbar>
        </>
    );
};
