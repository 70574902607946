import clsx from "clsx";
import Highlighter from "react-highlight-words";
import { shallowEqual } from "react-redux";
import { useDispatch, useSelector } from "src/store";
import { LoadingBox } from "src/ui/shared/Loading";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import {
    selectBatchActionIsActiveGroup,
    selectDetailsIsGroupSelected,
    selectSearchQuery,
    showDetails,
} from "@dashboard/devices/store";
import { Group as GroupType } from "@dashboard/devices/types";
import {
    selectLicenseListStatusByGroupId,
    selectLicensesByGroupIdWithFilter,
    selectLicensesListStatus,
} from "@dashboard/licenses/store/selectors";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box, Theme, Tooltip, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    licenseGroupTitle: {
        padding: "0.4rem",
        border: "1px solid transparent",
        borderRadius: "3px",
        cursor: "pointer",
        width: "calc(100% - 35px)",
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 auto",
        justifyContent: "space-between",
    },
    itemIcon: {
        marginRight: "6px",
        color: theme.palette.blue[200],
        fontSize: "12px",
    },
    informationBox: {
        display: "flex",
        alignItems: "center",
        marginRight: 12,
        fontSize: "0.8rem",
    },
    container: { display: "flex", justifyContent: "space-between" },
    iconBox: { display: "flex", flexWrap: "wrap" },
    center: { display: "flex", alignItems: "center" },
    float: { float: "right" },
    badge: {
        backgroundColor: theme.palette.blue[300],
        borderRadius: 16,
        padding: "2px 8px",
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.white[50],
        marginLeft: 8,
    },
}));
interface Props {
    group: GroupType;
}

export const GroupSummary = (props: Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const isThisGroupSelected = useSelector((state) => selectDetailsIsGroupSelected(state, props.group.id));
    const listsStatus = useSelector(selectLicensesListStatus);
    const listStatus = useSelector((state) => selectLicenseListStatusByGroupId(state, props.group.id));
    const searchQuery: string = useSelector(selectSearchQuery);
    const isBatchActionGroup = useSelector(selectBatchActionIsActiveGroup);
    const groupLicenses = useSelector(
        (state) => selectLicensesByGroupIdWithFilter(state, props.group.id),
        shallowEqual,
    );
    const groupDevices = groupLicenses.map((license) => license.device).filter((device) => device !== null);
    const unusedLicenseCount = groupLicenses.length - groupDevices.length;
    const onlineDeviceCount = groupDevices.filter((device) => device?.isOnline).length;
    const isGroupSelected = useSelector((state) => selectDetailsIsGroupSelected(state, props.group.id));
    const workspace = useSelector(selectWorkspace);
    const isDefault = workspace?.defaultGroupId === props.group.id;

    const onClick = () => {
        if (props.group.id) {
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: props.group.id,
                }),
            );
        }
    };

    return (
        <Box
            onClick={onClick}
            className={`${classes.licenseGroupTitle} ${isThisGroupSelected ? "border-primary" : ""}`}
            style={{
                width: `calc(100% - ${isBatchActionGroup ? "0px" : "35px"})`,
                backgroundColor: isGroupSelected ? theme.palette.blue[50] : theme.palette.shadow[100],
            }}
        >
            <Box className={classes.iconBox}>
                <Icon name="fas fa-network-wired" size={IconSize.small} className={classes.itemIcon} />
                <Highlighter searchWords={[searchQuery]} textToHighlight={props.group.name} />
                {isDefault ? <Box className={classes.badge}>Default</Box> : null}
            </Box>
            <Box className={classes.center}>
                <Tooltip placement="top" arrow title="Online devices">
                    <div className={classes.informationBox}>
                        <i className={clsx("fa-solid fa-wifi", classes.itemIcon, classes.float)}></i>
                        <div className={classes.center}>{`${onlineDeviceCount}/${groupDevices.length}`}</div>
                    </div>
                </Tooltip>
                <Tooltip placement="top" arrow title="Unused licenses">
                    <div className={classes.informationBox}>
                        <i className={clsx("fas fa-file", classes.itemIcon, classes.float)}></i>
                        <div className={classes.center}>{unusedLicenseCount}</div>
                    </div>
                </Tooltip>
                <Tooltip placement="top" arrow title="Group members">
                    <div className={classes.informationBox}>
                        <i className={clsx("fas fa-users", classes.itemIcon, classes.float)}></i>
                        <div className={classes.center}>{props.group.members.length}</div>
                    </div>
                </Tooltip>
            </Box>
            {(listsStatus === "pending" || listStatus === "pending") && <LoadingBox />}
        </Box>
    );
};
