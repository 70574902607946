import React from "react";
import { useDispatch } from "src/store";

import { Checkbox } from "@mui/material";
import { updateProduct } from "@products/store";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

export type ActionCellProps = {
    product: API.Product;
    isDeleting: boolean;
    onDelete: (id: number) => void;
    setEditProduct: React.Dispatch<React.SetStateAction<API.Product | null>>;
    setConfigProduct: React.Dispatch<React.SetStateAction<API.Product | null>>;
};
export const ActionCell = (props: ActionCellProps) => {
    return (
        <MinimalTableCell align="right">
            <SecondaryButton
                style={{ marginRight: 6 }}
                tooltipProps={{ title: "Edit product" }}
                onClick={() => {
                    props.setEditProduct(props.product);
                }}
            >
                <i className="fa-solid fa-pencil" />
            </SecondaryButton>
            <SecondaryButton
                style={{ marginRight: 6 }}
                tooltipProps={{ title: "Show config" }}
                size="small"
                onClick={() => {
                    props.setConfigProduct(props.product);
                }}
            >
                <i className="fa-solid fa-code" />
            </SecondaryButton>
            <DangerOutlinedButton onClick={() => props.onDelete(props.product.id)} loading={props.isDeleting}>
                <i className="fa-solid fa-trash-alt" />
            </DangerOutlinedButton>
        </MinimalTableCell>
    );
};

export type PublicCellProps = { product: API.Product };
export const PublicCell = (props: PublicCellProps) => {
    const dispatch = useDispatch();

    const setProductPublicFlag = async (isPublic: boolean) => {
        try {
            await dispatch(updateProduct({ ...props.product, isPublic })).unwrap();
            showSuccessToast("Product saved");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Error while editing product");
        }
    };

    return (
        <MinimalTableCell>
            <Checkbox
                defaultChecked={props.product.isPublic}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProductPublicFlag(e.target.checked)}
            />
        </MinimalTableCell>
    );
};
