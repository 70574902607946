import { useState, useEffect } from "react";

export const SolutionBuilderContent = () => {
    const [qcomHwChecked, setQcomHwChecked] = useState(true);
    const [nxpHwChecked, setNxpHwChecked] = useState(false);
    const [customHwChecked, setCustomHwChecked] = useState(false);
    const [headlessChecked, setHeadlessChecked] = useState(false);
    const [kioskChecked, setKioskChecked] = useState(false);
    const [customUiChecked, setCustomUiChecked] = useState(false);
    const [cloudChecked, setCloudChecked] = useState(false);
    const [fleetChecked, setFleetChecked] = useState(false);
    const [fleetAnalyticsChecked, setFleetAnalyticsChecked] = useState(false);

    const [hardwareImage, setHardwareImage] = useState("");
    const [serviceImage, setServiceImage] = useState("");

    //@ts-ignore
    // eslint-disable-next-line no-unused-vars
    const _serviceHandler = (c) => {
        if (/qcom/.test(c)) {
            setQcomHwChecked(!qcomHwChecked);
            setNxpHwChecked(false);
            setCustomHwChecked(false);
        }
        if (/nxp/.test(c)) {
            setNxpHwChecked(!nxpHwChecked);
            setQcomHwChecked(false);
            setCustomHwChecked(false);
        }
        if (/customhw/.test(c)) {
            setCustomHwChecked(!customHwChecked);
            setQcomHwChecked(false);
            setNxpHwChecked(false);
        }

        if (/headless/.test(c)) {
            setHeadlessChecked(!headlessChecked);
        }

        if (/kiosk/.test(c)) {
            setKioskChecked(!kioskChecked);
            setCustomUiChecked(false);
        }
        if (/customui/.test(c)) {
            setCustomUiChecked(!customUiChecked);
            setKioskChecked(false);
        }

        if (/cloud/.test(c) && fleetChecked && fleetAnalyticsChecked) {
            setCloudChecked(!cloudChecked);
            setFleetChecked(true);
            setFleetAnalyticsChecked(true);
        }

        if (/fleet/.test(c) && cloudChecked && fleetAnalyticsChecked) {
            setFleetChecked(!fleetChecked);
            setCloudChecked(true);
            setFleetAnalyticsChecked(true);
        }

        if (/analytics/.test(c) && cloudChecked && fleetChecked) {
            setFleetAnalyticsChecked(!fleetAnalyticsChecked);
            setCloudChecked(true);
            setFleetChecked(true);
        }

        if (/cloud/.test(c) && fleetChecked) {
            setCloudChecked(!cloudChecked);
            setFleetChecked(true);
        }

        if (/cloud/.test(c) && fleetAnalyticsChecked) {
            setCloudChecked(!fleetChecked);
            setFleetAnalyticsChecked(true);
        }

        if (/fleet/.test(c) && cloudChecked) {
            setFleetChecked(!fleetChecked);
            setCloudChecked(true);
        }

        if (/fleet/.test(c) && fleetAnalyticsChecked) {
            setFleetChecked(!cloudChecked);
            setFleetAnalyticsChecked(true);
        }

        if (/analytics/.test(c) && fleetChecked) {
            setFleetAnalyticsChecked(!fleetAnalyticsChecked);
            setFleetChecked(true);
        }

        if (/analytics/.test(c) && cloudChecked) {
            setFleetAnalyticsChecked(!fleetAnalyticsChecked);
            setCloudChecked(true);
        }

        if (/cloud/.test(c)) {
            setCloudChecked(!cloudChecked);
        }

        if (/fleet/.test(c)) {
            setFleetChecked(!fleetChecked);
        }

        if (/analytics/.test(c)) {
            setFleetAnalyticsChecked(!fleetAnalyticsChecked);
        }
    };

    useEffect(() => {
        setHardwareImage("");
        setServiceImage("");

        if (qcomHwChecked) {
            setHardwareImage("/img/builder/qualcomm.svg");
            if (headlessChecked) {
                setHardwareImage("/img/builder/qualcomm-screen-off-kiosk-off.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-off-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/qualcomm-screen-on.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/qualcomm-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/qualcomm-screen-on-customize-on.svg");
                }
            }
        }

        if (nxpHwChecked) {
            setHardwareImage("/img/builder/nxp.svg");
            if (headlessChecked) {
                setHardwareImage("/img/builder/nxp-screen-off-kiosk-off.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/nxp-screen-off-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/nxp-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/nxp-screen-on.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/nxp-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/nxp-screen-on-customize-on.svg");
                }
            }
        }

        if (customHwChecked) {
            setHardwareImage("/img/builder/customhw.svg");
            if (headlessChecked) {
                setHardwareImage("/img/builder/customhw-screen-off-kiosk-off.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/customhw-screen-off-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/customhw-screen-off-customize-on.svg");
                }
            } else {
                setHardwareImage("/img/builder/customhw-screen-on.svg");
                if (kioskChecked) {
                    setHardwareImage("/img/builder/customhw-kiosk-on.svg");
                } else if (customUiChecked) {
                    setHardwareImage("/img/builder/customhw-screen-on-customize-on.svg");
                }
            }
        }

        if (cloudChecked) {
            setServiceImage("/img/builder/data-management.svg");
        }

        if (fleetChecked) {
            setServiceImage("/img/builder/fleet-management.svg");
        }

        if (fleetAnalyticsChecked) {
            setServiceImage("/img/builder/fleet-analytics.svg");
        }

        if (cloudChecked && fleetChecked) {
            setServiceImage("/img/builder/data-and-fleet-management.svg");
        }

        if (cloudChecked && fleetAnalyticsChecked) {
            setServiceImage("/img/builder/data-and-fleet-analytics.svg");
        }

        if (fleetChecked && fleetAnalyticsChecked) {
            setServiceImage("/img/builder/fleet-management-and-fleet-analytics.svg");
        }

        if (cloudChecked && fleetChecked && fleetAnalyticsChecked) {
            setServiceImage("/img/builder/all-services.svg");
        }
    }, [
        qcomHwChecked,
        nxpHwChecked,
        customHwChecked,
        headlessChecked,
        kioskChecked,
        customUiChecked,
        cloudChecked,
        fleetChecked,
        fleetAnalyticsChecked,
        hardwareImage,
        serviceImage,
    ]);

    return (
        <div className="solution-section-wrapper">
            <div className="headline-wrapper">
                <p>Tailored solution for smart devices</p>
                <h2>Build your product with embedded Android</h2>
            </div>
            <div className="solution-wrapper">
                <div className="form-wrapper">
                    <div className="options-wrapper hardware">
                        <h2>Hardware</h2>
                        <form className="pt-0">
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Qualcomm</span>
                                {/* TODO: swap with mui switch */}
                                {/* <Switch
                                    checked={qcomHwChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch qcom"
                                /> */}
                            </label>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">NXP</span>
                                {/* <Switch
                                    checked={nxpHwChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch nxp"
                                /> */}
                            </label>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Custom</span>
                                {/* <Switch
                                    checked={customHwChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch customhw"
                                /> */}
                            </label>
                        </form>
                    </div>
                    <div className="options-wrapper hardware">
                        <h2>Peripherals</h2>
                        <form>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Headless</span>
                                {/* <Switch
                                    className="react-switch"
                                    checked={headlessChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    id="material-switch headless"
                                /> */}
                            </label>
                        </form>
                    </div>
                    <div className="options-wrapper software">
                        <h2>Software</h2>
                        <form className="pt-0">
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Kiosk mode</span>
                                {/* <Switch
                                    checked={kioskChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch kiosk"
                                /> */}
                            </label>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Custom UI</span>
                                {/* <Switch
                                    checked={customUiChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch customui"
                                /> */}
                            </label>
                        </form>
                    </div>
                    <div className="options-wrapper services">
                        <h2>Services</h2>
                        <form className="pt-0">
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">App management</span>
                                {/* <Switch
                                    checked={cloudChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    className="react-switch"
                                    id="material-switch cloud"
                                /> */}
                            </label>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Fleet management</span>
                                {/* <Switch
                                    className="react-switch"
                                    checked={fleetChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    id="material-switch fleet"
                                /> */}
                            </label>
                            <label htmlFor="material-switch" className="switch-wrapper">
                                <span className="switch-label">Fleet analytics</span>
                                {/* <Switch
                                    className="react-switch"
                                    checked={fleetAnalyticsChecked}
                                    onChange={(a, b, c) => serviceHandler(c)}
                                    offColor="#dfe4e8"
                                    onColor="#dfe4e8"
                                    onHandleColor="#1698ff"
                                    offHandleColor="#a7b4be"
                                    handleDiameter={15}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={15}
                                    width={32}
                                    id="material-switch analytics"
                                /> */}
                            </label>
                        </form>
                    </div>
                </div>
                <div className="img-wrapper">
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `#full-solution {
                                height: 100%;
                                width: 100%;
                                background-image: url(${hardwareImage}), url(${serviceImage});
                                background-size: contain, contain;
                                background-position: center, center;
                                background-repeat: no-repeat, no-repeat;
                            }`,
                        }}
                    />
                    <div id="full-solution" />
                </div>
            </div>
        </div>
    );
};
