import React from "react";
import { useDispatch, useSelector } from "src/store";

import { closeDetails, selectPaneTab, setPaneTab } from "@devices/store";
import { License } from "@devices/types";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Chip, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { SecondaryButton } from "@shared/CustomButton";

import CommandTab from "../shared/CommandTab/CommandTab";
import { MenuLicense } from "../shared/MenuLists/MenuLicense";
import { PaneElement } from "../shared/PaneElement";
import { useStyle } from "../shared/style";
import AppTab from "./AppTab/AppTab";
import FileTab from "./FileTab/FileTab";
import HardwareTab from "./HardwareTab/HardwareTab";
import LicenseTab from "./LicenseTab/LicenseTab";
import NetworkTab from "./NetworkTab/NetworkTab";
import DeviceSummaryTab from "./SummaryTab/DeviceSummaryTab";
import LicenseSummaryTab from "./SummaryTab/LicenseSummaryTab";
import SystemTab from "./SystemTab/SystemTab";
import { VncButton } from "./VncButon";

const generalTabs = ["SUMMARY", "LICENSE"];
const deviceTabs = ["APPS", "FILES", "COMMANDS"];
const deviceStateTabs = ["OS", "HARDWARE", "NETWORK"];

export const LicenseSidePanel = (props: License) => {
    const dispatch = useDispatch();
    const classes = useStyle();

    const value = useSelector(selectPaneTab);
    const isUniversalLicense = Boolean(props.universalGroupId && props.universalGroupName);

    const onChange = (_event: React.ChangeEvent<object>, tabTitle: string) => {
        dispatch(setPaneTab(tabTitle));
    };

    React.useEffect(() => {
        const tabs = [
            ...generalTabs,
            ...(props.device ? deviceTabs : []),
            ...(props.device?.lastState ? deviceStateTabs : []),
        ];
        if (!tabs.includes(value)) {
            dispatch(setPaneTab("SUMMARY"));
        }
    }, [value, dispatch, props.device]);

    return (
        <TabContext value={value}>
            <PaneElement.HeaderBox>
                <Box>
                    <Box className={classes.headerTitleBox}>
                        <Typography variant="h6">License</Typography>
                        {isUniversalLicense ? <Chip label="Universal" color="primary" size="small" /> : null}
                    </Box>
                    <Typography variant="body2">{props.activationCode}</Typography>
                </Box>

                <Box className={classes.buttonBox}>
                    <VncButton {...props} />
                    <MenuLicense {...props} />
                    <SecondaryButton onClick={() => dispatch(closeDetails())}>
                        <i className="fas fa-times" />
                    </SecondaryButton>
                </Box>
            </PaneElement.HeaderBox>

            <TabList onChange={onChange} variant="scrollable" scrollButtons>
                <Tab
                    value="SUMMARY"
                    label="Summary"
                    iconPosition="start"
                    icon={<i className="fas fa-tachometer-alt" />}
                />
                <Tab value="LICENSE" label="License" iconPosition="start" icon={<i className="fas fa-desktop" />} />

                <Tab
                    value="OS"
                    label="OS"
                    iconPosition="start"
                    icon={<i className="fas fa-desktop" />}
                    sx={{ display: props.device?.lastState ? "unset" : "none" }}
                />

                <Tab
                    value="APPS"
                    label="Apps"
                    iconPosition="start"
                    icon={<i className="fas fa-th" />}
                    sx={{ display: props.device ? "unset" : "none" }}
                />
                <Tab
                    value="FILES"
                    label="Files"
                    iconPosition="start"
                    icon={<i className="fa-solid fa-file" />}
                    sx={{ display: props.device ? "unset" : "none" }}
                />
                <Tab
                    value="HARDWARE"
                    label="Hardware"
                    iconPosition="start"
                    icon={<i className="fas fa-microchip" />}
                    sx={{ display: props.device?.lastState ? "unset" : "none" }}
                />
                <Tab
                    value="NETWORK"
                    label="Network"
                    iconPosition="start"
                    icon={<i className="fas fa-network-wired" />}
                    sx={{ display: props.device?.lastState ? "unset" : "none" }}
                />
                <Tab
                    value="COMMANDS"
                    label="Commands"
                    iconPosition="start"
                    icon={<i className="fas fa-terminal" />}
                    sx={{ display: props.device ? "unset" : "none" }}
                />
            </TabList>

            <Box className={classes.contentBox}>
                <TabPanel value="SUMMARY">
                    {props.device ? <DeviceSummaryTab {...props} /> : <LicenseSummaryTab {...props} />}
                </TabPanel>
                <TabPanel value="LICENSE">
                    <LicenseTab {...props} />
                </TabPanel>
                {props.device && props.device.lastState ? (
                    <React.Fragment>
                        <TabPanel value="OS">
                            <SystemTab deviceState={props.device.lastState} groupId={props.groupId} />
                        </TabPanel>
                        <TabPanel value="HARDWARE">
                            <HardwareTab deviceState={props.device.lastState} />
                        </TabPanel>
                        <TabPanel value="NETWORK">
                            <NetworkTab deviceState={props.device.lastState} />
                        </TabPanel>
                    </React.Fragment>
                ) : null}
                {props.device ? (
                    <React.Fragment>
                        <TabPanel value="APPS">
                            <AppTab device={props.device} groupId={props.groupId} />
                        </TabPanel>
                        <TabPanel value="FILES">
                            <FileTab device={props.device} groupId={props.groupId} />
                        </TabPanel>
                        <TabPanel value="COMMANDS">
                            <CommandTab device={props.device} />
                        </TabPanel>
                    </React.Fragment>
                ) : null}
            </Box>
        </TabContext>
    );
};
