import { useState } from "react";
import { FilterDialog } from "src/ui/shared/filter/FilterDialog";

import { ReleaseFile } from "@dashboard/downloads/types/index";
import { Column } from "@tanstack/react-table";

export const FilterWrapper = ({
    column,
    allFiles,
}: {
    column: Column<ReleaseFile, unknown>;
    allFiles: ReleaseFile[];
}) => {
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const columnFilterType = column.columnDef.meta?.filterVariant;

    const codenameOptions = allFiles.map((item) => item.codename);
    const productOptions = allFiles.map((item) => item.productName);
    const channelOptions = ["Live", "Beta", "Nightly"];

    switch (columnFilterType) {
        case "releasesCodename":
            return (
                <FilterDialog
                    title="Codenames"
                    options={codenameOptions}
                    property="releasesCodename"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "releasesProduct":
            return (
                <FilterDialog
                    title="Products"
                    options={productOptions}
                    property="releasesProduct"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        case "channel":
            return (
                <FilterDialog
                    title="Channels"
                    options={channelOptions}
                    property="channel"
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            );
        default:
            return null;
    }
};
