import clsx from "clsx";
import { useSelector } from "react-redux";
import authService from "services/authService";
import { permissionService } from "services/permissionService/permissionService";
import { RootState } from "src/store";

import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { GroupMember, GroupRoles } from "@devices/types";
import { selectGroupById } from "@groups/store/selectors";
import { Box, MenuItem, Select, TableRow, Theme, Typography } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { Tooltip } from "@shared/Tooltip";
import { selectWorkspace, selectWorkspaceMemberRole } from "@workspaces/store/selectors";

import { alternatingBg } from "../styles";

const useStyles = makeStyles((theme: Theme) => ({
    borderless: {
        borderBottom: "none !important",
    },
    lineThrough: {
        textDecorationLine: "line-through",
        textDecorationStyle: "solid",
    },
    inputItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexShrink: 0,
        "& .MuiInputBase-root": {
            height: 36,
            fontSize: 14,
        },
    },
    nameContainer: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 5,
    },
    statusLabel: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        fontSize: 12,
        padding: "2px 6px",
        borderRadius: 12,
        marginLeft: 4,
    },
    membershipIcon: {
        fontSize: 20,
        marginBottom: -2,
    },
    iconBox: {
        display: "flex",
        justifyContent: "center",
        width: 25,
    },
}));

interface Props {
    index: number;
    member: GroupMember;
    loading: boolean;
    groupId: number | null;
    removeInProgress: boolean;
    handleRole(role: GroupRoles, email: string): void;
    handleRemove(checked: boolean, email: string): void;
}

export const MemberListItem = (props: Props) => {
    const classes = useStyles();
    const isExpired = useSelector(selectSubscriptionExpired);

    const group = useSelector((state: RootState) => (props.groupId ? selectGroupById(state, props.groupId) : null));
    const workspace = useSelector(selectWorkspace);
    const ownEmail = authService.getCurrentUser()?.email;
    const currentLoggedUserRole = useSelector(selectWorkspaceMemberRole);

    const isMemberOwner = props.member.role === "Owner";
    const isMemberGuest = props.member.role === "Guest";

    const { groupAbility } = permissionService();
    const mayManageMember = groupAbility(group || null, workspace).can("manage", "Member");

    const getDeleteTooltip = () => {
        if (isMemberGuest) {
            return "You can't remove workspace guests";
        }
        if (isMemberOwner && currentLoggedUserRole === "Manager") {
            return "Managers cannot delete owners";
        }
        if (props.member.role === "User" && props.member.email === ownEmail) {
            return "Delete member";
        }
        if (!mayManageMember) {
            return "Your role doesn't allow member deletion";
        }
        return "Delete member";
    };

    const isDeleteButtonDisabled = () => {
        if (isMemberGuest) {
            return true;
        }
        if (isMemberOwner && currentLoggedUserRole === "Manager") {
            return true;
        }
        if (props.member.email === ownEmail && props.member.role === "User") {
            return false;
        }
        return !mayManageMember;
    };

    const onRoleChange: SelectInputProps["onChange"] = (e) => {
        props.handleRole(e.target.value as GroupRoles, props.member.email);
    };

    //allowing changing role to owner in groups but disabling it for workspace managers in workspaces page table
    const displaySelect =
        groupAbility(group || null, workspace).can("manage", "Member") &&
        !(currentLoggedUserRole === "Manager" && !props.groupId && props.member.role === "Owner");

    const pendingTooltipMessage = props.groupId ? (
        <span>Pending members received an invitation mail to join the group</span>
    ) : (
        <span>Pending members received an invitation mail to join the workspace</span>
    );

    return (
        <TableRow style={alternatingBg(props.index)}>
            <MinimalTableCell className={classes.borderless} align="justify">
                <Box className={clsx(props.removeInProgress && classes.lineThrough, classes.nameContainer)}>
                    {props.groupId ? (
                        <Box className={classes.iconBox}>
                            <Tooltip title="Group member" placement="top">
                                <i className={clsx("fa-solid fa-user", classes.membershipIcon)} />
                            </Tooltip>
                        </Box>
                    ) : null}
                    <Typography>{props.member?.email ?? "-"} </Typography>
                    {props.member.status === "Pending" && (
                        <Tooltip title={props.member.status === "Pending" ? pendingTooltipMessage : ""}>
                            <span className={classes.statusLabel}>Pending</span>
                        </Tooltip>
                    )}
                </Box>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="justify">
                <Box className={clsx(classes.inputItem, props.removeInProgress && classes.lineThrough)}>
                    {displaySelect ? (
                        <Tooltip title="Subscription expired" hide={!isExpired} placement="left">
                            <Select
                                onChange={onRoleChange}
                                defaultValue={props.member.role}
                                disabled={props.removeInProgress || props.loading || isExpired}
                                style={{ width: 200 }}
                            >
                                <MenuItem
                                    value="Owner"
                                    disabled={!props.groupId && currentLoggedUserRole === "Manager"}
                                >
                                    Owner
                                </MenuItem>
                                <MenuItem value="Manager">Manager</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                        </Tooltip>
                    ) : (
                        <Typography>{props.member.role}</Typography>
                    )}
                </Box>
            </MinimalTableCell>
            <MinimalTableCell className={classes.borderless} align="right">
                {!props.removeInProgress ? (
                    <div className={classes.buttonBox}>
                        <DangerOutlinedButton
                            onClick={() => props.handleRemove(true, props.member.email)}
                            disabled={isDeleteButtonDisabled()}
                            tooltipProps={{ title: getDeleteTooltip() }}
                        >
                            <i className="fas fa-trash-alt" />
                        </DangerOutlinedButton>
                    </div>
                ) : (
                    <PrimaryOutlinedButton onClick={() => props.handleRemove(false, props.member.email)}>
                        Undo
                    </PrimaryOutlinedButton>
                )}
            </MinimalTableCell>
        </TableRow>
    );
};
