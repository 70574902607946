import _ from "lodash";
import { RootState } from "src/store";
import {
    selectLicensesByGroupId,
    selectLicensesByGroupIdWithFilter,
} from "src/ui/containers/dashboard/licenses/store/selectors";

import { devicesPageFilterState, selectFilterIsActive, selectSearchQuery } from "@dashboard/devices/store";
import { groupsAdapter, showEmptyGroups } from "@dashboard/devices/store/helpers";
import { selectSubscription, selectSubscriptionById } from "@dashboard/devices/store/selectors/subscriptions";
import { Group, GroupRoles } from "@dashboard/devices/types";
import { selectFiles } from "@dashboard/files/store/selectors";
import { selectWorkspace, selectWorkspaces } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

import { GroupOverviewData } from "../GroupsPage";

const defaultSelectors = groupsAdapter.getSelectors<RootState>((state) => state.devices.list.groups.items);

const selectGroupById = defaultSelectors.selectById;
const selectGroupCommands = createSelector(
    (state: RootState) => state,
    (_state: RootState, groupId: number) => groupId,
    (state, groupId) => {
        const group = defaultSelectors.selectById(state, groupId);
        return group?.commands ?? [];
    },
);
const selectGroupMemberRoleByEmail = (state: RootState, groupId: number, email: string | null): GroupRoles | null => {
    const group = defaultSelectors.selectById(state, groupId);
    const groupMember = group?.members?.find((member) => member.email === email);
    if (!groupMember) {
        return null;
    }

    return groupMember.role;
};

const selectGroups = createSelector([(state: RootState) => state, defaultSelectors.selectAll], (state, groups) => {
    const subscription = selectSubscription(state);
    return subscription ? groups.filter((item) => subscription.groupIds.includes(item.id)) : [];
});
const selectGroupsByIds = createSelector(
    selectGroups,
    (_state: RootState, groupIds: number[]) => groupIds,
    (groups, groupIds) => {
        return groups.filter((group) => groupIds.includes(group.id));
    },
);
const selectGroupsEntities = defaultSelectors.selectEntities;
const selectGroupsIdsBySubscriptionId = createSelector(
    (state: RootState) => state,
    (_state: RootState, subscriptionId: number) => subscriptionId,
    (state, subscriptionId) => {
        const subscription = selectSubscriptionById(state, subscriptionId);
        return subscription ? subscription.groupIds : [];
    },
);
const selectGroupsMembersByWorkspace = createSelector(
    (state: RootState) => state,
    selectWorkspace,
    selectGroups,
    (state, workspace, groups) => {
        const groupsMembers = groups.map((item) => item.members).flat();
        return workspace?.members
            ? _.uniqBy([...workspace.members, ...groupsMembers], "email")
            : _.uniqBy(groupsMembers, "email");
    },
);
const selectGroupsNames = createSelector(selectGroups, (groups) => groups.map((group) => group.name));
const selectGroupsOverviewData = createSelector(
    (state: RootState) => state,
    selectGroups,
    selectWorkspace,
    selectFiles,
    (state, groups, workspace, files) => {
        const groupArray: GroupOverviewData[] = [];

        groups.map((group) => {
            const groupLicenses = selectLicensesByGroupId(state, group.id);
            const groupDevices = groupLicenses.map((license) => license.device).filter((device) => device !== null);
            const onlineDeviceCount = groupDevices.filter((device) => device?.isOnline).length;
            const groupPolicy = group.policy && group.policy.length > 0;

            const onlineDevicePercentage =
                groupDevices.length > 0 ? Math.round((onlineDeviceCount / groupDevices.length) * 100).toFixed(1) : 0;

            const compliantDeviceCount = groupDevices.filter((device) => device?.lastState?.policy?.successful).length;
            const compliantDevicePercentage =
                groupDevices.length > 0 ? ((compliantDeviceCount / groupDevices.length) * 100).toFixed(1) : 0;
            const numberOfFiles = files.filter((file) => file.groupId === group.id).length.toString();

            const newGroupData = {
                name: group.name,
                description: group.description || "-",
                onlineDevices: !groupDevices.length ? "" : `${onlineDevicePercentage.toString()}%`,
                compliantDevices: groupPolicy && groupDevices.length ? `${compliantDevicePercentage.toString()}%` : "",
                numberOfFiles: numberOfFiles.toString(),
                numberOfDevices: groupDevices.length.toString(),
                id: group.id,
                isDefault: workspace?.defaultGroupId === group.id,
            };

            groupArray.push(newGroupData);
        });

        return groupArray;
    },
);
const selectGroupsByWorkspaceWithFilter = createSelector(
    (state: RootState) => state,
    (state: RootState) => state.workspaces.currentWorkspaceId,
    (state, workspaceId) => {
        const searchQuery = selectSearchQuery(state);
        const shouldFilter = selectFilterIsActive(state);
        const filters = devicesPageFilterState(state)?.properties;
        const workspaces = selectWorkspaces(state);
        const workspace = workspaces.find((item) => item.id === workspaceId);

        if (!workspace) {
            return [];
        }

        const subscription = selectSubscriptionById(state, workspace.subscription);
        if (!subscription) {
            return [];
        }

        const isNewGroup = (groupId: number): boolean => {
            const currentlySavedGroups = sessionStorage.getItem("newGroups");
            if (currentlySavedGroups) {
                const idArray: number[] = JSON.parse(currentlySavedGroups);
                return idArray.some((savedGroupId) => savedGroupId === groupId);
            } else {
                return false;
            }
        };

        const groups = subscription.groupIds.filter((groupId) => {
            const group = selectGroupById(state, groupId);

            if (searchQuery) {
                return (
                    (group && group.name.toLocaleLowerCase().includes(searchQuery)) ||
                    selectLicensesByGroupIdWithFilter(state, groupId).length > 0
                );
            } else if (shouldFilter) {
                return selectLicensesByGroupIdWithFilter(state, groupId).length == 0
                    ? showEmptyGroups({ searchQuery, filters }) || isNewGroup(groupId)
                    : true;
            } else {
                return true;
            }
        });

        return groups.map((groupId) => selectGroupById(state, groupId)).filter((group) => group) as Group[];
    },
);

export {
    selectGroupById,
    selectGroupCommands,
    selectGroupMemberRoleByEmail,
    selectGroups,
    selectGroupsByIds,
    selectGroupsByWorkspaceWithFilter,
    selectGroupsEntities,
    selectGroupsIdsBySubscriptionId,
    selectGroupsMembersByWorkspace,
    selectGroupsNames,
    selectGroupsOverviewData,
};
