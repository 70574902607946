import _ from "lodash";
import { RootState, useSelector } from "src/store";

import { selectDevicesByGroupId } from "@dashboard/devices/store/selectors/devices";
import { useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@shared/Tooltip";
import { CellContext } from "@tanstack/react-table";

import { GroupOverviewData } from "../../GroupsPage";

const useStyles = makeStyles(() => ({
    tooltip: {
        display: "flex",
        justifyContent: "center",
        marginRight: 4,
    },
    icon: {
        display: "inline-block",
        verticalAlign: "middle",
        fontSize: 16,
    },
}));

export const ComplianceStatusIcon = (props: CellContext<GroupOverviewData, unknown>) => {
    const classes = useStyles();
    const value = _.toNumber(_.trimEnd(props.getValue<string>(), "%"));
    const theme = useTheme();

    const groupDevices = useSelector((state: RootState) => selectDevicesByGroupId(state, props.row.original.id));
    const policyNotDefined = props.row.original.compliantDevices === "";
    const hasNonCompliantDevices = value < 100;

    let iconClassName;
    let color;
    let tooltipText;

    if (policyNotDefined) {
        iconClassName = "fa-regular fa-circle";
        color = theme.palette.grey[500];
        tooltipText = !groupDevices.length ? "Group is empty" : "No policy defined";
    } else if (hasNonCompliantDevices) {
        iconClassName = "fas fa-exclamation-circle";
        color = theme.palette.orange[100];
        tooltipText = "Some devices are not compliant";
    } else {
        iconClassName = "fas fa-check-circle";
        color = theme.palette.green[50];
        tooltipText = "All devices are compliant";
    }

    return (
        <Tooltip title={tooltipText} className={`${classes.tooltip}`}>
            <span className={`${classes.icon}`} style={{ color }}>
                <i className={iconClassName} />
            </span>
        </Tooltip>
    );
};
