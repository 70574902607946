import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { convertToLocalDate } from "src/ui/containers/dashboard/devices/utils/dates";
import { TextField } from "src/ui/shared/form/TextField";
import { Tooltip } from "src/ui/shared/Tooltip";

import { fetchAllGroups } from "@admin/api";
import { PageContainer } from "@dashboard/shared/styles";
import { EmteriaRoles } from "@data/roles";
import { ConfirmDialog } from "@devices/components/shared";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    TableBody,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { withRequiredRole } from "@navigation/utils/withRequiredRole";
import { deleteProduct, fetchAllProducts } from "@products/api";
import { ProductAdmin } from "@products/types";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { LoadingBox } from "@shared/Loading";
import { MinimalTable, MinimalTableCell, MinimalTableHeader } from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { fuzzyFilter } from "@shared/table/Tanstack.utils";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import CustomBuildConfigDialog from "./components/CustomBuildConfigDialog";
import { EditProductDialog } from "./components/EditProductDialog";
import { ActionCell, ActionCellProps, PublicCell, PublicCellProps } from "./components/TableCells";

const useStyles = makeStyles({
    searchBox: { margin: "0 auto", maxWidth: 500, display: "flex", marginBottom: 30 },
    noDataBox: { display: "flex", justifyContent: "center", alignItems: "center", marginTop: 60, flex: 1 },
    searchButton: {
        height: 30,
        marginLeft: 5,
    },
    card: { marginBottom: 20 },
    cardContent: { display: "flex", gap: 10, "&.MuiCardContent-root": { paddingTop: 0 } },

    cardTitleBox: { display: "flex", gap: 10, alignItems: "flex-end" },
    cell: { width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
    flex: { display: "flex", gap: 6 },
});

type FormValues = {
    ownerEmail: string;
};

type ProductTableData = {
    id: number;
    workspaceId: number;
    groupId: number | null;
    name: string;
    codename: string;
    creationDate: string;
    builds: number;
    androidVersion: string;
    isPublic: PublicCellProps;
    actions: ActionCellProps;
};

const columnHelper = createColumnHelper<ProductTableData>();

const tableColumns = [
    columnHelper.accessor("id", {
        id: "id",
        enableSorting: true,
        header: "ID",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
    }),
    columnHelper.accessor("workspaceId", {
        id: "workspaceId",
        enableSorting: true,
        header: () => (
            <Tooltip title="Workspace ID" placement="top">
                <span style={{ fontWeight: 600 }}>WID</span>
            </Tooltip>
        ),
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
    }),
    columnHelper.accessor("groupId", {
        id: "groupId",
        enableSorting: true,
        header: () => (
            <Tooltip title="Group ID" placement="top">
                <span style={{ fontWeight: 600 }}>GID</span>
            </Tooltip>
        ),
        cell: (info) => <MinimalTableCell>{info.getValue() || "-"}</MinimalTableCell>,
    }),
    columnHelper.accessor("name", {
        id: "name",
        header: "Name",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("codename", {
        id: "codename",
        header: "Codename",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("creationDate", {
        id: "creationDate",
        header: "Creation date",
        cell: (info) => (
            <MinimalTableCell>
                <Tooltip title={convertToLocalDate(info.row.original.creationDate, "ISO", "dateTime")}>
                    <span>{convertToLocalDate(info.row.original.creationDate, "ISO", "date")}</span>
                </Tooltip>
            </MinimalTableCell>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor("androidVersion", {
        id: "androidVersion",
        header: "Android version",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("builds", {
        id: "builds",
        header: "Builds",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("isPublic", {
        id: "isPublic",
        header: "Public",
        cell: (info) => <PublicCell {...info.getValue()} />,
        enableSorting: false,
    }),
    columnHelper.accessor("actions", {
        id: "actions",
        header: "Actions",
        cell: (info) => <ActionCell {...info.getValue()} />,
        enableSorting: false,
    }),
];

type State<T> = {
    data: T[];
    loading: boolean;
};
const DefaultState = {
    data: [],
    loading: false,
};

type DeleteState = {
    id: number | null;
    open: boolean;
    loading: boolean;
};
const DefaultDeleteState = {
    id: null,
    open: false,
    loading: false,
};

const AdminProductsPage = () => {
    const [query, setQuery] = React.useState("");
    const [products, setProducts] = React.useState<State<API.Product>>(DefaultState);
    const [ownerGroups, setOwnerGroups] = React.useState<State<API.Group>>(DefaultState);
    const [deleteState, setDeleteState] = React.useState<DeleteState>(DefaultDeleteState);
    const [editProduct, setEditProduct] = React.useState<ProductAdmin | null>(null);
    const [configProduct, setConfigProduct] = React.useState<ProductAdmin | null>(null);

    const classes = useStyles();

    const table = useReactTable({
        data: products.data.map((item) => ({
            id: item.id,
            workspaceId: item.workspaceId,
            groupId: item.groupId,
            name: item.customBuildConfig?.productName ?? "Unknown product name",
            codename: item.codename,
            creationDate: item.created,
            builds: item.numberOfBuilds,
            androidVersion: item.androidVersion,
            isPublic: { product: item },
            actions: {
                product: item,
                isDeleting: deleteState.loading,
                onDelete: (id: number) => setDeleteState({ id, open: true, loading: false }),
                setConfigProduct,
                setEditProduct,
            },
        })),
        columns: tableColumns,
        filterFns: { fuzzy: fuzzyFilter },
        state: { globalFilter: query },
        defaultColumn: { size: 0 },
        autoResetPageIndex: false,
        initialState: {
            sorting: [{ id: "name", desc: false }],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
        onGlobalFilterChange: setQuery,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });
    const tableRows = table.getPaginationRowModel().rows;

    const onDelete = async () => {
        if (deleteState.id) {
            try {
                setDeleteState((prev) => ({ ...prev, loading: true }));
                await deleteProduct(deleteState.id);
                showSuccessToast("Product deleted successfully");
                setProducts((prev) => ({ ...prev, data: prev.data.filter((item) => item.id !== deleteState.id) }));
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
                setQuery("");
            } finally {
                setDeleteState(DefaultDeleteState);
            }
        }
    };
    const onSearch = async () => {
        setOwnerGroups((prev) => ({ ...prev, loading: true }));
        try {
            const data = await fetchAllGroups({ userEmail: query });
            setOwnerGroups({ data: data ?? [], loading: false });
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
            setOwnerGroups({ data: [], loading: false });
        }
    };

    React.useLayoutEffect(() => {
        setProducts((prev) => ({ ...prev, loading: true }));
        void fetchAllProducts()
            .then((data) => setProducts({ data, loading: false }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setProducts({ data: [], loading: false });
            });
    }, []);

    if (products.loading) {
        return <LoadingBox />;
    }

    if (!products.data.length && !products.loading) {
        return (
            <Box className={classes.noDataBox}>
                <Typography>No products</Typography>
            </Box>
        );
    }

    return (
        <PageContainer>
            <Form<FormValues>
                onSubmit={onSearch}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Card className={classes.card}>
                            <CardHeader
                                title={
                                    <Box className={classes.cardTitleBox}>
                                        <Typography variant="h6">Search</Typography>
                                    </Box>
                                }
                            />
                            <CardContent className={classes.cardContent}>
                                <TextField
                                    name="ownerEmail"
                                    label="Owner Email"
                                    fullWidth
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value)}
                                />
                                <PrimaryButton
                                    type="submit"
                                    disabled={_.isEmpty(query) || ownerGroups.loading}
                                    loading={ownerGroups.loading}
                                >
                                    Search
                                </PrimaryButton>
                                <SecondaryButton disabled={_.isEmpty(query)} onClick={() => setQuery("")}>
                                    Clear
                                </SecondaryButton>
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
            <TableContainer component={Paper}>
                <MinimalTable>
                    <MinimalTableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Header key={header.id} {...header} />
                                ))}
                            </TableRow>
                        ))}
                    </MinimalTableHeader>
                    <TableBody>
                        {tableRows.length ? (
                            tableRows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) =>
                                        flexRender(cell.column.columnDef.cell, {
                                            ...cell.getContext(),
                                            key: cell.id,
                                        }),
                                    )}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <MinimalTableCell colSpan={table.getAllLeafColumns().length} align="center">
                                    No data matching your query
                                </MinimalTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MinimalTable>
            </TableContainer>

            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50]}
                count={table.getFilteredRowModel().rows.length}
                rowsPerPage={table.getState().pagination.pageSize}
                page={table.getState().pagination.pageIndex}
                onPageChange={(event, newPage) => table.setPageIndex(newPage)}
                onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            />

            <ConfirmDialog
                title="Delete product"
                content="Are you sure you want to delete this product?"
                dangerButton
                primaryActionText="Delete"
                open={deleteState.open}
                onConfirm={onDelete}
                onClose={() => setDeleteState((prev) => ({ ...prev, open: false }))}
            />
            {editProduct ? (
                <EditProductDialog
                    product={editProduct}
                    onClose={() => setEditProduct(null)}
                    open={true}
                    title="Edit product"
                    products={products.data}
                    setProducts={(items) => setProducts((prev) => ({ ...prev, data: items }))}
                />
            ) : null}
            <CustomBuildConfigDialog
                product={configProduct}
                open={!!configProduct}
                onClose={() => setConfigProduct(null)}
            />
        </PageContainer>
    );
};
export default withRequiredRole(AdminProductsPage, [EmteriaRoles.EmteriaDeveloperRole]);
