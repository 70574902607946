import { RootState } from "src/store";

import { selectGroupById } from "@dashboard/groups/store/selectors";
import { selectLicenseById } from "@dashboard/licenses/store/selectors";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { createSelector } from "@reduxjs/toolkit";

import { Subscription } from "../../types";
import { subscriptionsAdapter } from "../helpers";

const defaultSelectors = subscriptionsAdapter.getSelectors<RootState>(
    (state) => state.devices.list.subscriptions.items,
);

const selectSubscriptions = defaultSelectors.selectAll;
const selectSubscriptionById = defaultSelectors.selectById;

const selectSubscription = createSelector(
    [selectSubscriptions, (state: RootState) => state],
    (subscriptions, state) => {
        const currentWorkspace = selectWorkspace(state);
        return subscriptions.find((subscription) => subscription.id === currentWorkspace?.subscription) || null;
    },
);
const selectSubscriptionVariantBySubscriptionId = (state: RootState, subscriptionId: number) => {
    const subscription = selectSubscriptionById(state, subscriptionId);
    return subscription ? subscription.variantName : null;
};
const selectSubscriptionVariantByGroupId = (state: RootState, groupId: number) => {
    const group = selectGroupById(state, groupId);
    return group ? selectSubscriptionVariantBySubscriptionId(state, group.subscriptionId) : null;
};
const selectSubscriptionVariantByLicenseId = (state: RootState, licenseId: number) => {
    const license = selectLicenseById(state, licenseId);
    return license ? selectSubscriptionVariantByGroupId(state, license.groupId) : null;
};
const selectSubscriptionLimitByLimitType = (state: RootState, subscriptionId: number, type: API.LimitType) => {
    const subscriptions = selectSubscriptions(state);
    const subscription = subscriptions.find((sub) => sub.id === subscriptionId);
    const limit = subscription?.limits.find((lim) => lim.type === type)?.value;
    return limit ?? 0;
};
const selectSubscriptionExpired = (state: RootState) => {
    const workspace = selectWorkspace(state);
    const subscriptions = selectSubscriptions(state);
    const subscription = Object.values(subscriptions).find((item) => item?.id === workspace?.subscription);
    return Boolean(subscription?.isExpired);
};
const selectSubscriptionFdroidRepos = createSelector(selectSubscription, (subscription) => {
    return subscription?.fdroidRepos || [];
});
const selectSubscriptionsFdroidRepos = createSelector(selectSubscriptions, (subscriptions) => {
    return subscriptions.flatMap((subscription) => subscription.fdroidRepos ?? []);
});
const selectSubscriptionsFdroidReposById = (state: RootState, subscriptionId: Subscription["id"]) =>
    selectSubscriptionById(state, subscriptionId)?.fdroidRepos ?? [];

export {
    selectSubscription,
    selectSubscriptionById,
    selectSubscriptionExpired,
    selectSubscriptionFdroidRepos,
    selectSubscriptionsFdroidRepos,
    selectSubscriptionsFdroidReposById,
    selectSubscriptionLimitByLimitType,
    selectSubscriptions,
    selectSubscriptionVariantByGroupId,
    selectSubscriptionVariantByLicenseId,
};
