import { useSelector } from "src/store";
import { selectWorkspaceStreamLimit } from "src/ui/containers/dashboard/workspaces/store/selectors";

import { useCommands } from "@devices/components/Commands/hooks";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { License } from "@devices/types";
import authService from "@services/authService";
import euporiaService from "@services/euporiaService";
import { createNvcSession } from "@services/vncService";
import { SecondaryButton } from "@shared/CustomButton";
import { showErrorToast } from "@shared/toasts/Toasts";

const getReasonMessage = (props: {
    isSubscriptionExpired: boolean;
    isVncEnabled: boolean;
    hasOnlineDevice: boolean;
    limit: number;
}) => {
    if (props.limit <= 0) {
        return "Screen mirroring is unavailable because your stream limit has been reached";
    }
    if (!props.hasOnlineDevice) {
        return "Device must be online";
    }
    if (!props.isVncEnabled) {
        return "Screen mirroring is disabled on the device";
    }
    return "Start screen mirroring";
};

export const VncButton = (props: License) => {
    const user = authService.getCurrentUser();
    const { createMdmCommand, loadingState } = useCommands();
    const hasOnlineDevice = !!props.device?.isOnline;
    const isVncEnabled = !!props.device?.lastState?.properties?.["persist.sys.vncr.enabled"];
    const limit = useSelector(selectWorkspaceStreamLimit);
    const isSubscriptionExpired = useSelector((state) => selectSubscriptionExpired(state));
    const hasScreenMirror = !isSubscriptionExpired && isVncEnabled && hasOnlineDevice && limit > 0;

    const onClick = async () => {
        if (!props.device?.id) {
            return;
        }
        try {
            const response = await createNvcSession(props.device.id);
            const command = {
                command: "sendBroadcast",
                caption: "Start screen mirroring",
                intent: {
                    component: "com.emteria.vnc/.receivers.CommandReceiver",
                    action: "emteria.intent.action.START_REVERSE_VNC",
                    extras: [
                        { key: "host", type: "string", value: response.data.model.host },
                        {
                            key: "token",
                            type: "string",
                            value: response.data.model.token,
                        },
                    ],
                },
            };
            await createMdmCommand({ deviceId: props.device.id, json: command });
            window.open(
                `https://vnc.staging.emteria.com/vnc.html?autoconnect=true&resize=scale&token=${response.data.model.token}`,
                "_blank",
                "width=600,height=400,left=200,top=200",
            );
            euporiaService.createTransaction("vnc", {
                device_id: props.device.id,
                user_email: user?.email,
            });
        } catch (_error) {
            showErrorToast("Something went wrong during the VNC connection.");
        }
    };

    return (
        <SecondaryButton
            onClick={onClick}
            disabled={!hasScreenMirror || loadingState.createLoading}
            loading={loadingState.createLoading}
            tooltipProps={{
                title: getReasonMessage({ isSubscriptionExpired, isVncEnabled, hasOnlineDevice, limit }),
                placement: "left",
            }}
        >
            <i className="fab fa-chromecast" />
        </SecondaryButton>
    );
};
