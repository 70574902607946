import React, { useEffect, useState } from "react";
import { theme } from "src/providers/ThemeProvider";
import { ProductAdmin } from "src/ui/containers/dashboard/products/types";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: theme.palette.grey[100],
        padding: 24,
        borderRadius: 1,
        overflow: "auto",
    },
}));

interface CustomBuildConfigDialogProps {
    open: boolean;
    product: ProductAdmin | null;
    onClose: () => void;
}

const CustomBuildConfigDialog: React.FC<CustomBuildConfigDialogProps> = ({ open, onClose, product }) => {
    const classes = useStyles();
    const [currentProduct, setCurrentProduct] = useState<ProductAdmin | null>(null);

    useEffect(() => {
        if (open && product) {
            setCurrentProduct(product);
        }
    }, [open, product]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                style: {
                    width: "auto",
                    maxWidth: "90%",
                    minWidth: "600px",
                },
            }}
        >
            <DialogTitle>Custom Build Config</DialogTitle>
            <DialogContent>
                {currentProduct?.customBuildConfig ? (
                    <Box component="pre" className={classes.container}>
                        {JSON.stringify(currentProduct.customBuildConfig, null, 2)}
                    </Box>
                ) : (
                    <Typography variant="body2">No custom build config available.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={onClose}>Close</SecondaryButton>
            </DialogActions>
        </Dialog>
    );
};

export default CustomBuildConfigDialog;
